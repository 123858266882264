<template>
  <div>
    <el-card style="min-height: 45rem">
      <el-table :data="list">
        <el-table-column prop="id" label="编号" show-overflow-tooltip></el-table-column>
        <el-table-column label="股东名称id" show-overflow-tooltip>
          <template #default="s">
            {{ s.row.nickname }}({{ s.row.uid }})
          </template>
        </el-table-column>
        <el-table-column label="加入日期" show-overflow-tooltip>
          <template #default="s">
            {{ s.row.created_at | date }}
          </template>
        </el-table-column>
        <el-table-column label="分红明细" show-overflow-tooltip>
          <template #default="s">
            <shareholder-award-log :uid="s.row.uid">
              <el-tag type="success" effect="dark">查看明细</el-tag>
            </shareholder-award-log>
          </template>
        </el-table-column>
        <el-table-column label="操作" show-overflow-tooltip>
          <template #header>
            <Y_choose_member @change="chooseU">
              <el-button type="success">添加股东</el-button>
            </Y_choose_member>
          </template>
          <template #default="s">
            <el-button size="mini" @click="choose(s.row.uid)" type="danger">撤销</el-button>
          </template>
        </el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
          </el-pagination>
        </template>
      </el-table>
    </el-card>
  </div>
</template>

<script>

import Y_choose_member from "@/components/shop/y_choose_member.vue";
import ShareholderAwardLog from "@/components/shop/plugin/partnership/ShareholderAwardLog.vue";

const searchFormTpl = {
  nickname: "",
  uid: 0,
  last_num: -1,
  used_num: -1,
  total_award: -1,
}

export default {
  components: {ShareholderAwardLog, Y_choose_member},
  name: "manage",
  data() {
    return {
      list: [],
      page: 1,
      total: 0,
      searchForm: {...searchFormTpl}
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    pageChange(e) {
      this.page = e;
      this.load();
    },
    chooseU(e) {
      this.choose(e.id);
    },
    choose(id) {
      this.$u.api.shop.plugin.partnership.shareholderChoose({id}).then(() => {
        this.$message.success("操作成功");
        this.load();
      })
    },
    load() {
      this.$u.api.shop.plugin.partnership.shareholderSearch({
        page: this.page,
      }).then(res => {
        this.list = res.list;
        this.total = res.total;
      })
    },
  },
}
</script>

<style scoped>

</style>